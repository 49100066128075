<template>
  <div class="container">
    <RoleCard theme="student">
      <template #label>Приглашение на НТО</template>
      <template #label_img>
        <img
          src="@/assets/images/reg_school.svg"
          alt="иконка" />
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <div v-if="organization">
              <p class="mb-m">
                Вас приглашают присоединиться к организации &laquo;{{
                  organization.full_name || organization.short_name
                }}&raquo; как наставника участников НТО.
              </p>
            </div>

            <div v-if="!pending">
              <div v-if="user">
                <div v-if="user.id && !isMentor">
                  <p class="mb-s">
                    Так как вы&nbsp;зарегистрированы как участник НТО, принять
                    приглашение не&nbsp;получится. Присоединиться
                    к&nbsp;организации могут только пользователи,
                    зарегистрированные как наставники.
                  </p>
                  <BaseButton
                    id="mentor-invite-user-index"
                    tag="router-link"
                    :to="{ name: 'user-index' }"
                    >В кабинет участника</BaseButton
                  >
                </div>
                <div v-else-if="hasMembership">
                  <p class="mb-l">Вы уже состоите в этой организации</p>
                  <BaseButton
                    id="mentor-invite-hasmmbership"
                    tag="router-link"
                    :to="{ name: 'mentor-index' }"
                    >В кабинет наставника</BaseButton
                  >
                </div>
                <div v-else-if="user.id && isMentor">
                  <div v-if="freeOrganizations.length">
                    <p class="mb-s">
                      Чтобы привязать организацию, указанную в&nbsp;вашей
                      учетной записи на&nbsp;платформе &laquo;Талант&raquo;,
                      к&nbsp;приглашающей организации, поставьте галочку ниже.
                    </p>
                    <div class="agree-join mb-m">
                      <RegCheckbox
                        :checked="agreeJoin"
                        class="agree-join__check"
                        @input="handleAgree" />
                      <span
                        class="agree-join__text"
                        @click="handleAgree">
                        Я хочу привязать свою организацию как дочернюю.
                      </span>
                    </div>

                    <div v-if="agreeJoin">
                      <div v-if="freeOrganizations.length > 1">
                        <div class="mb-s">
                          Мы нашли у вас несколько организаций. Выберите ту,
                          которую желаете присоединить:
                        </div>
                        <div class="row">
                          <div class="col-sm-6 col-xs-12 mb-s">
                            <BaseSelect
                              v-model="selectedOrganization"
                              :options="freeOrganizations"
                              :searchable="false"
                              :show-no-results="false"
                              :preselect-first="true"
                              label="full_name"
                              :allow-empty="false"
                              placeholder="Выбрать организацию"></BaseSelect>
                          </div>
                        </div>
                      </div>
                      <div v-if="freeOrganizations.length === 1">
                        Ваша организация &laquo;{{
                          freeOrganizations[0].full_name ||
                          freeOrganizations[0].short_name
                        }}&raquo; будет привязана как дочерняя.
                      </div>
                    </div>
                  </div>

                  <div class="mt-s">
                    <BaseButton
                      id="mentor-invite-accept"
                      :disabled="!!error"
                      @click="handleAccept"
                      >Принять приглашение</BaseButton
                    >&emsp;
                    <BaseButton
                      v-if="!!error"
                      id="mentor-invite-mentor-index"
                      tag="router-link"
                      :to="{ name: 'mentor-index' }"
                      >В кабинет Наставника</BaseButton
                    >
                  </div>
                </div>
                <div
                  v-else-if="!user.id"
                  class="mt-s">
                  <BaseButton
                    id="mentor-invite-reg-and-accept"
                    :disabled="!!error"
                    @click="handleRegMentor"
                    >Принять приглашение</BaseButton
                  >
                </div>
              </div>
              <div v-else>
                <p class="mb-l">
                  Войдите в&nbsp;свою учетную запись на&nbsp;платформе
                  &laquo;Талант&raquo;, чтобы принять приглашение.
                </p>
                <BaseButton
                  id="mentor-invite-login"
                  tag="a"
                  :href="`${$store.state.clientApiPrefix}/auth/go?next=${$route.fullPath}`"
                  >Войти</BaseButton
                >
              </div>
            </div>
            <BaseSpinner v-else />

            <div v-if="error">
              <div class="form-error">{{ error }}</div>
            </div>
          </div>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
/**
 * @page InviteAdmin
 * Экран инвайта ментора в организацию
 * Получаем информацию о текущем пользователе
 * 1. если есть `talent_user` но нет юзера ОНТИ - то регистрируем ментора и джойним в организацию
 * 2. если есть ОНТИ юзер с ролью `participant`- то выводим ошибку по роли
 * 3. если есть ОНТИ юзер с ролью `mentor - то джойним его в организацию
 */
import { mapGetters } from "vuex";
import { talentRequest, request } from "@/services/api";
import RoleCard from "@/components/registration/RoleCard.vue";
import RegCheckbox from "@/components/registration/RegCheckbox";

import { MENTOR_ROLE } from "@/constants";
export default {
  name: "InviteAdmin",
  components: { RoleCard, RegCheckbox },
  metaInfo() {
    return {
      title: "Приглашение в организацию",
    };
  },
  data() {
    return {
      organization: null,
      pending: true,
      error: "",
      selectedOrganization: null,
      agreeJoin: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
    }),
    freeOrganizations() {
      // организация не имеет дочерних и не имеет юр. лица
      return this.userOrganizations.filter(
        (item) => !item.parent_organization && !item.legal_entity
      );
    },
    /**
     * Проверяет, является ли уже текущий
     * наставник админом/оунером организации
     */
    hasMembership() {
      const { userOrganizations, isMentor } = this;
      if (!isMentor) return false;
      const current = Number(this.$route.params.org);

      return userOrganizations.some((org) => org.id === current);
    },

    userOrganizations() {
      return this.$store.state.mentor.organizations;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.pending = true;
      // Получаем информацию об организации
      try {
        const { data: organization } = await talentRequest({
          method: "GET",
          url: `/api/organizations/${this.$route.params.org}/`,
        });
        this.organization = organization;
      } catch (error) {
        if (error.status === 404) {
          this.error =
            "Организация не найдена. Проверьте корректность ссылки, или попросите владельца организации выслать ссылку повторно.";
        } else {
          this.error = error.message;
        }
      }
      try {
        await this.$store.dispatch("user/getMe");
      } catch (error) {
        console.log("error", error);
        if (!this.user) {
          // Пользователю предлагают авторизоваться по инвайту от юзера
          window.dataLayer?.push({
            event: "nto_auth_with_talant_oauth_suggest",
            eventCategory: "nto_auth_with_talant",
            eventAction: "oauth_suggest",
            inviteType: "invite_from_organization",
          });
        }
      }
      try {
        // если ментор, то получаем список организаций
        if (this.user && this.isMentor) {
          await this.$store.dispatch("mentor/getMyOrganizations");
        }
      } catch (error) {
        // ничего не делаем
      }
      this.pending = false;
    },
    handleAgree() {
      this.agreeJoin = !this.agreeJoin;
    },
    async acceptInvite() {
      const { user } = this;
      const { code, org } = this.$route.params;
      try {
        await talentRequest({
          method: "POST",
          url: `/api/users/${user.talent_id}/organizations/${org}/admins/invite/`,
          params: { code: code },
          data: {
            user_id: user.talent_id,
          },
        });

        // Запрос на добавление организации в качестве дочерней
        if (this.agreeJoin && this.freeOrganizations.length) {
          const organizationId = this.selectedOrganization
            ? this.selectedOrganization.id
            : this.freeOrganizations[0].id;

          await talentRequest({
            method: "POST",
            url: `/api/users/${user.talent_id}/organizations/${organizationId}/invite/requests/`,
            data: {
              target: org,
            },
          });
        }
      } catch (error) {
        this.error = error.message;
        throw error;
      }
    },
    async handleAccept() {
      try {
        await this.acceptInvite();
        this.$router.push({
          name: "mentor-index",
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    async handleRegMentor() {
      // Если приняли - то регистрируем ментора
      await request({
        method: "POST",
        url: "/user/register",
        data: {
          role: MENTOR_ROLE,
        },
      });

      // Авторизуем в онти
      await this.$store.dispatch("user/getMe");

      // Принимаем инвайт в организацию
      await this.acceptInvite();
      // Идем на главную для менторов
      this.$router.push({
        name: "mentor-index",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.agree-join {
  display: flex;
  cursor: pointer;
  align-items: center;

  &__check {
    margin-right: 10px;
  }
}
</style>
